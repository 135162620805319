import React from 'react'
import Layout from '../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'

export default function Services() {
  return (
    <Layout>
      <section>
        <div className='container'>
            <div className='row p-1 d-flex justify-content-center'>
                <div className="col-sm-12 col-lg-8 mb-5">
                    
                    <h4>Insulated Window/Single Pane Glass Repair</h4>

                    <p>
                        If you have some insulated glass windows around the house that have started to develop a foggy film on the interior of the glass and you're unable to clean it,  or if it has cracked or broken glass, Beheben Glass offers the replacement of these insulated units as an alternative to replacing the whole window frame, offering a much more financially reasonable option as opposed to replacing the whole window.
                    </p>

                    <p>
                        Also any broken single paned glass can be replaced without replacing the windows frames.
                    </p>
                </div>

            </div>

            <div className='row mb-5 d-flex justify-content-center'>
                <div className="col-sm-12 col-lg-4 mb-3"> 

                    <h4 className='d-flex justify-content-center mb-3'><u>Before</u></h4>
                    
                    <div className='d-flex justify-content-center'>
                        <a target='_blank' href='https://beheben.net/images/Side-before-1.jpg'>
                            <StaticImage
                                src='../images/Side-before-1.jpg'
                                height = { 300 }
                                alt='logo'
                                placeholder='tracedSVG'
                                quality='40'
                            />
                        </a>
                    </div>

                    <small className='d-flex justify-content-center'>(Click to Enlarge)</small> 

                </div> 
                <div className="col-sm-12 col-lg-4"> 

                    <h4 className='d-flex justify-content-center mb-3'><u>After</u></h4>

                    <div className='d-flex justify-content-center'>
                        <a target='_blank' href='https://beheben.net/images/Side-after-1024x575-1.jpg'>
                            <StaticImage
                                src='../images/Side-after-1024x575-1.jpg'
                                height = { 300 }
                                alt='logo'
                                placeholder='tracedSVG'
                                quality='40'
                            />
                        </a>

                    </div>


                    <small className='d-flex justify-content-center'>(Click to Enlarge)</small> 

</div> 
                
            </div>
        </div>
      </section>   
    </Layout>
  )
}
